import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './styles.css';
import { Routes, Route, Navigate, Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { HOST } from './constants.js';

console.log(HOST);

// React JS implementation of the Question Feedback System using Bootstrap for styling

// Add this component at the top level
const DatabaseManagement = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [uploadMode, setUploadMode] = useState('append');
    const [databaseStats, setDatabaseStats] = useState(null);

    useEffect(() => {
        fetchDatabaseStats();
    }, []);

    const fetchDatabaseStats = async () => {
        try {
            // Note: Removing the course parameter requirement
            const response = await axios.get(`${HOST}:8000/admin/db-stats`);
            setDatabaseStats(response.data);
        } catch (error) {
            console.error('Error fetching database stats:', error);
            setDatabaseStats({
                total: 0,
                min_id: 0,
                max_id: 0
            });
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            previewExcel(file);
        }
    };

    const previewExcel = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        try {
            setLoading(true);
            const response = await axios.post(`${HOST}:8000/admin/upload-excel/preview`, formData);
            setPreview(response.data);
            setMessage('');
        } catch (error) {
            setMessage('Error analyzing file: ' + (error.response?.data?.error || error.message));
            setPreview(null);
        } finally {
            setLoading(false);
        }
    };

    const uploadExcel = async () => {
        if (!file) {
            setMessage('Please select a file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('mode', uploadMode);
        formData.append('preview', 'false');

        try {
            setLoading(true);
            const response = await axios.post(`${HOST}:8000/admin/upload-excel`, formData);
            setMessage('Database updated successfully');
            setPreview(null);
            setFile(null);
            fetchDatabaseStats();
        } catch (error) {
            setMessage('Error updating database: ' + (error.response?.data?.error || error.message));
        } finally {
            setLoading(false);
        }
    };

    const deleteAllQuestions = async () => {
        if (window.confirm('Are you sure you want to delete ALL questions? This action cannot be undone!')) {
            try {
                setLoading(true);
                await axios.delete(`${HOST}:8000/admin/questions`);
                setMessage('All questions deleted successfully');
                fetchDatabaseStats();
            } catch (error) {
                setMessage('Error deleting questions');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleExportDatabase = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${HOST}:8000/admin/export-database`, {
                responseType: 'blob'  // Important for handling file downloads
            });
            
            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `database_export_${new Date().toISOString().split('T')[0]}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            
            setMessage('Database exported successfully');
        } catch (error) {
            setMessage('Error exporting database: ' + (error.response?.data?.error || error.message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container py-4">
            <h3>Database Management</h3>
            
            {/* Database Stats */}
            {databaseStats && (
                <div className="card mb-4">
                    <div className="card-body">
                        <h5>Database Statistics</h5>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="border rounded p-3 text-center">
                                    <h6>Total Questions</h6>
                                    <h3>{databaseStats?.total ?? 0}</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border rounded p-3 text-center">
                                    <h6>First Question ID</h6>
                                    <h3>{databaseStats?.min_id ?? 0}</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border rounded p-3 text-center">
                                    <h6>Last Question ID</h6>
                                    <h3>{databaseStats?.max_id ?? 0}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Upload Section */}
            <div className="card mb-4">
                <div className="card-body">
                    <h5>Upload Excel File</h5>
                    <div className="mb-3">
                        <input
                            type="file"
                            accept=".xlsx,.xls"
                            onChange={handleFileUpload}
                            className="form-control"
                            disabled={loading}
                        />
                        <div className="form-text">Only .xlsx and .xls files are supported</div>
                    </div>

                    {file && (
                        <div className="mb-3">
                            <label className="form-label">Upload Mode</label>
                            <select 
                                className="form-select"
                                value={uploadMode}
                                onChange={(e) => setUploadMode(e.target.value)}
                            >
                                <option value="append">Append (Keep existing data)</option>
                                <option value="replace">Replace (Clear existing data)</option>
                            </select>
                        </div>
                    )}

                    {/* Preview Section */}
                    {preview && (
                        <div className="mt-4">
                            <h6>Upload Preview</h6>
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Total Rows:</td>
                                            <td>{preview?.summary?.total_rows || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>New Questions:</td>
                                            <td>{preview?.summary?.new_rows || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Duplicate Questions:</td>
                                            <td>{preview?.summary?.duplicate_rows || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Modified Questions:</td>
                                            <td>{preview?.summary?.modified_rows || 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* Add preview details if needed */}
                            {preview.details && (
                                <div className="mt-3">
                                    <h6>Preview Details</h6>
                                    {preview.details.new_rows?.length > 0 && (
                                        <div className="mb-3">
                                            <h6 className="text-success">New Questions ({preview.details.new_rows.length})</h6>
                                            <ul className="list-group">
                                                {preview.details.new_rows.map((row, idx) => (
                                                    <li key={idx} className="list-group-item">
                                                        {row.new_question}
                                                    </li>
                                                ))}
                                            </ul>
                                            {preview.has_more?.new_rows && (
                                                <small className="text-muted">And more...</small>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            <button 
                                className="btn btn-primary"
                                onClick={uploadExcel}
                                disabled={loading}
                            >
                                {loading ? 'Uploading...' : 'Confirm Upload'}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Add Export Section before Danger Zone */}
            <div className="card mb-4">
                <div className="card-header bg-success text-white">
                    <h5 className="mb-0">Export Database</h5>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="mb-0">Export Questions</h6>
                            <p className="text-muted mb-0">
                                Download all questions as an Excel file
                            </p>
                        </div>
                        <button 
                            className="btn btn-success"
                            onClick={handleExportDatabase}
                            disabled={loading}
                        >
                            {loading ? 'Exporting...' : 'Export to Excel'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Danger Zone */}
            <div className="card border-danger">
                <div className="card-header bg-danger text-white">
                    <h5 className="mb-0">Danger Zone</h5>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="mb-0">Delete All Questions</h6>
                            <p className="text-muted mb-0">
                                This will permanently delete all questions from the database.
                            </p>
                        </div>
                        <button 
                            className="btn btn-danger"
                            onClick={deleteAllQuestions}
                            disabled={loading}
                        >
                            Delete All Questions
                        </button>
                    </div>
                </div>
            </div>

            {/* Messages */}
            {message && (
                <div className={`alert mt-3 ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
                    {message}
                </div>
            )}
        </div>
    );
};

// Update the getSelectedQuestion helper function to handle null cases better
const getSelectedQuestion = (questions = [], selectedQuestionId = null) => {
    if (!questions || !Array.isArray(questions) || questions.length === 0) {
        return {
            old_question: '',
            new_question: '',
            edited_question: '',
            feedback: '',
            difficulty: '',  // Remove default 'Easy'
            image_path: null
        };
    }
    
    const question = questions.find(q => q && q.id === selectedQuestionId);
    return question || {
        old_question: '',
        new_question: '',
        edited_question: '',
        feedback: '',
        difficulty: '',  // Remove default 'Easy'
        image_path: null
    };
};

// Update getDifficultyColor to handle any difficulty value
const getDifficultyColor = (difficulty) => {
    const difficultyMap = {
        "moderate to difficult": 'bg-danger text-white',
        moderate: 'bg-warning text-white',
        easy: 'bg-success text-white'
    };
    return difficultyMap[String(difficulty).toLowerCase()] || 'bg-secondary text-white';
};

// Update AdminLayout to properly handle navigation
const AdminLayout = ({ username, onLogout }) => (
    <div className="dashboard">
        <button 
            className="btn btn-warning mt-3 mb-3" 
            onClick={(e) => {
                e.preventDefault();
                onLogout();
            }}
        >
            Logout
        </button>
        <nav className="mb-4">
            <Link to="/admin" className="btn btn-primary me-2">Dashboard</Link>
            <Link to="/admin/database" className="btn btn-primary me-2">Database Management</Link>
            <Link to="/admin/users" className="btn btn-primary me-2">User Management</Link>

        </nav>
        <Outlet />
    </div>
);
// Add new component for viewing questions
// Add new helper function for question status at the top of ViewQuestions component
const getQuestionStatusInfo = (question) => {
    if (question.approved) {
        return { color: 'success', status: 'Approved', icon: '✓' };
    }
    if (question.updated) {
        return { color: 'warning', status: 'Edited', icon: '����' };
    }
    return { color: 'light', status: 'Not Started', icon: '○' };
};

const QuestionContent = ({ question }) => {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex justify-content-between mb-2">
                    <h6 className="card-subtitle text-muted">Current Version</h6>
                    {question.editor && (
                        <small className="text-muted">
                            Last edited by: {question.editor}
                        </small>
                    )}
                </div>
                <div className="markdown-content border rounded p-3 bg-light">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {question.edited_question || question.new_question}
                    </ReactMarkdown>
                </div>

                {question.edited_question && question.edited_question !== question.new_question && (
                    <div className="mt-3">
                        <h6 className="card-subtitle text-muted mb-2">Original Version</h6>
                        <div className="markdown-content border rounded p-3 bg-white">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {question.new_question}
                            </ReactMarkdown>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const QuestionDisplay = ({ question }) => {
    return (
        <div className="question-display">
            <div className="row">
                <div className="col-md-6">
                    <div className="version-card h-100">
                        <div className="card">
                            <div className="card-header bg-primary text-white">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Original Version</h6>
                                </div>
                            </div>
                            <div className="card-body">
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {question.new_question}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-6">
                    <div className="version-card h-100">
                        <div className="card">
                            <div className="card-header bg-success text-white">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Current Version</h6>
                                    {question.editor && (
                                        <small>Edited by: {question.editor}</small>
                                    )}
                                </div>
                            </div>
                            <div className="card-body">
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {question.edited_question || question.new_question}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Add a new component for the detailed question view
const QuestionDetailView = ({ question, onClose, onEdit }) => {
    const [activeComparison, setActiveComparison] = useState('original-new'); // Add this state
    const [nextRole, setNextRole] = useState('');
    const [feedback, setFeedback] = useState('');
    const [approving, setApproving] = useState(false);
    
    const handleApproveAndAssign = async () => {
        if (!nextRole) {
            alert('Please select the next role');
            return;
        }
        
        setApproving(true);
        try {
            const response = await fetch(`${HOST}:8000/questions/approve-and-assign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    question_id: question.id,
                    next_role: nextRole,
                    feedback: feedback,
                current_username: localStorage.getItem('username'), // Include current username
                }),
            });
            
            onClose();
            // Refresh questions list
            window.location.reload();
        } catch (error) {
            alert('Error approving question: ' + (error.response?.data?.error || error.message));
        } finally {
            setApproving(false);
        }
    };

    // Add this function to get role options based on current user's role
    const getRoleOptions = () => {
        const currentUserRole = localStorage.getItem('userRole');
        let availableRoles = [];
        
        // Define the workflow sequence
        const workflowSequence = {
            'teacher': ['designer', 'copyright', 'admin'],
            'designer': ['teacher', 'copyright', 'admin'],
            'copyright': ['teacher', 'designer', 'admin'],
            'admin': ['teacher', 'designer', 'copyright']
        };
        
        // Get the available roles based on current role
        availableRoles = workflowSequence[currentUserRole] || [];
        
        return availableRoles;
    };

    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex justify-content-between w-100 align-items-center">
                            <h5 className="modal-title">Question {question.id}</h5>
                            <div className="d-flex gap-2 align-items-center">
                                <span className={`badge ${getDifficultyColor(question.difficulty)}`}>
                                    {question.difficulty}
                                </span>
                                <button className="btn btn-primary" onClick={() => onEdit(question)}>
                                    Edit Question
                                </button>
                                <button className="btn-close" onClick={onClose}></button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        {/* Add ImageGalleryComponent here, before the version toggle buttons */}
                        <ImageGallery 
                            questionId={question.id}
                            onImageDeleted={() => {
                                // Optionally refresh question data if needed
                            }}
                        />

                        {/* Version Toggle Buttons */}
                        <div className="btn-group w-100 mb-4">
                            <button
                                className={`btn ${activeComparison === 'original-new' ? 'btn-primary' : 'btn-outline-primary'}`}
                                onClick={() => setActiveComparison('original-new')}
                            >
                                Original vs New Version
                            </button>
                            <button
                                className={`btn ${activeComparison === 'new-edited' ? 'btn-primary' : 'btn-outline-primary'}`}
                                onClick={() => setActiveComparison('new-edited')}
                            >
                                New vs Edited Version
                            </button>
                        </div>

                        {/* Version Comparison */}
                        {activeComparison === 'original-new' && (
                            <div className="comparison-section">
                                <h6 className="border-bottom pb-2 mb-3">Original vs New Version</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card h-100">
                                            <div className="card-header bg-secondary text-white">
                                                Original Question
                                            </div>
                                            <div className="card-body">
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                    {question.old_question || 'No original version available'}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card h-100">
                                            <div className="card-header bg-primary text-white">
                                                New Version
                                            </div>
                                            <div className="card-body">
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                    {question.new_question}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeComparison === 'new-edited' && (
                            <div className="comparison-section">
                                <h6 className="border-bottom pb-2 mb-3">New vs Edited Version</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card h-100">
                                            <div className="card-header bg-primary text-white">
                                                New Version
                                            </div>
                                            <div className="card-body">
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                    {question.new_question}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card h-100">
                                            <div className="card-header bg-success text-white d-flex justify-content-between">
                                                <span>Current Edited Version</span>
                                                {question.editor && (
                                                    <small>Edited by: {question.editor}</small>
                                                )}
                                            </div>
                                            <div className="card-body">
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                    {question.edited_question || 'No edits made yet'}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Additional Information */}
                        <div className="mt-4">
                            {question.feedback && (
                                <div>
                                    <h6 className="border-bottom pb-2 mb-3">Feedback</h6>
                                    <div className="alert alert-info">
                                        {question.feedback}
                                    </div>
                                </div>
                            )}
                        </div>
                        </div>
                    <div className="modal-footer">
                        <div className="container">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <select 
                                        className="form-select"
                                        value={nextRole}
                                        onChange={(e) => setNextRole(e.target.value)}
                                    >
                                        <option value="">Select next role...</option>
                                        {getRoleOptions().map(role => (
                                            <option key={role} value={role}>
                                                {role.charAt(0).toUpperCase() + role.slice(1)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 mt-2">
                                <textarea
                                        className="form-control"
                                        placeholder="Add feedback for the next reviewer..."
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        rows="3"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-secondary" onClick={onClose}>
                                    Close
                                </button>
                                <button 
                                    className="btn btn-success"
                                    onClick={handleApproveAndAssign}
                                    disabled={approving || !nextRole}
                                >
                                    {approving ? 'Approving...' : 'Approve & Assign'}
                                </button>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    </div>
    );
};

// Update the question card in the grid to show a condensed version
const QuestionCard = ({ question, onSelect }) => {
    const statusInfo = getQuestionStatusInfo(question);
    
    return (
        <div 

                   
            onClick={() => onSelect(question.id)} // Make sure we pass just the ID
            className="question-item p-3 border-bottom hover-bg"
            style={{ cursor: 'pointer' }}
        >
            <div className="d-flex justify-content-between align-items-start">
                <div className="d-flex gap-2 align-items-center">
                    <small className="text-muted">#{question.id}</small>
                    <span className={`badge bg-${statusInfo.color}`}>{statusInfo.status}</span>
                </div>
                {question.difficulty && (
                    <span className={`badge ${getDifficultyColor(question.difficulty)}`}>
                        {question.difficulty}
                    </span>
                )}
            </div>
            <div className="mt-2 question-preview">
                <div style={{ 
                    maxHeight: '60px', 
                    overflow: 'hidden',
                    fontSize: '0.9rem',
                    position: 'relative'
                }}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {question.edited_question || question.new_question}
                    </ReactMarkdown>
                    <div className="fade-overlay"></div>
                </div>
            </div>
            {/* Add reviewer badges */}
            <div className="mt-2 d-flex gap-2">
                {Object.entries(question.reviewers).map(([role, reviewer]) => 
                    reviewer && (
                        <span key={role} className="badge bg-secondary">
                            {role.charAt(0).toUpperCase() + role.slice(1)} ✓
                        </span>
                    )
                )}
            </div>
        </div>
    );
};

// Update ViewQuestions to use minimal loading
const ViewQuestions = () => {
    const navigate = useNavigate();  // Add this at the top with other hooks
    const [questionIds, setQuestionIds] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('all'); // Add this new state
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDifficulty, setSelectedDifficulty] = useState('all');
    const [showEditModal, setShowEditModal] = useState(false);
    const [newQuestionText, setNewQuestionText] = useState("");
    const [feedbackText, setFeedbackText] = useState("");
    const [difficulty, setDifficulty] = useState("Easy");
    const [approvalStatus, setApprovalStatus] = useState("pending");
    const [difficulties, setDifficulties] = useState([]);
    const [selectedReviewer, setSelectedReviewer] = useState('all');

    useEffect(() => {
        fetchMinimalQuestions();
        fetchDifficulties();
    }, [selectedReviewer]); // Add selectedReviewer to dependencies

    const fetchMinimalQuestions = async () => {
        const username = localStorage.getItem('username');
        const primary_discipline = localStorage.getItem('selectedPrimaryDiscipline');
        const secondary_discipline = localStorage.getItem('selectedSecondaryDiscipline');
        try {
            let url = `${HOST}:8000/questions/minimal/${username}?primary_discipline=${primary_discipline}&secondary_discipline=${secondary_discipline}`;
            if (selectedReviewer !== 'all') {
                url += `&reviewed_by=${selectedReviewer}_user`;
            }
            const response = await axios.get(url);
            setQuestionIds(response.data);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const fetchDifficulties = async () => {
        try {
            const response = await axios.get(`${HOST}:8000/difficulties`);
            setDifficulties(response.data);
        } catch (error) {
            console.error('Error fetching difficulties:', error);
        }
    };

    const handleQuestionSelect = async (questionId) => {
        setLoading(true);
        try {
            const response = await axios.get(`${HOST}:8000/questions/detail/${Number(questionId)}`);
            setSelectedQuestion(response.data);
            setNewQuestionText(response.data.edited_question || response.data.new_question);
            setFeedbackText(response.data.feedback || "");
            setDifficulty(response.data.difficulty || "Easy");
            setApprovalStatus(response.data.approved ? 'approved' : 'pending');
        } catch (error) {
            console.error('Error fetching question details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditQuestion = () => {
        if (selectedQuestion && newQuestionText) {
            axios.post(`${HOST}:8000/questions/edit`, {
                question_id: selectedQuestion.id,
                new_question: newQuestionText,
                editor: localStorage.getItem('username'),
                difficulty: difficulty, // Use the difficulty from state
                feedback: feedbackText,
                approval_status: approvalStatus
            })
                .then(response => {
                    console.log('Question edited successfully:', response.data);
                    // Refresh questions immediately after edit
                    fetchMinimalQuestions();
                    setSelectedQuestion(null);
                })
                .catch(error => {
                    console.error('Error editing question:', error);
                });
        }
    };

    // Add this filter function
    const filterQuestionsByStatus = (questions, status) => {
        switch(status) {
            case 'approved':
                return questions.filter(q => q.approved);
            case 'pending':
                return questions.filter(q => q.updated && !q.approved);
            case 'not-started':
                return questions.filter(q => !q.updated && !q.approved);
            default:
                return questions;
        }
    };

    // Add new filter function
    const filterQuestions = (questions) => {
        let filteredQuestions = questions;
        
        // Filter by status
        if (activeTab !== 'all') {
            filteredQuestions = filterQuestionsByStatus(filteredQuestions, activeTab);
        }
        
        // Filter by difficulty
        if (selectedDifficulty !== 'all') {
            filteredQuestions = filteredQuestions.filter(
                q => q.difficulty?.toLowerCase() === selectedDifficulty.toLowerCase()
            );
        }
        
        // Filter by search query
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filteredQuestions = filteredQuestions.filter(q => 
                String(q.id).includes(query)
            );
        }
        
        return filteredQuestions;
    };

    // Add this function to get counts for each reviewer type
    const getReviewerCounts = (questions) => {
        return questions.reduce((counts, q) => {
            Object.entries(q.reviewers).forEach(([role, reviewer]) => {
                if (reviewer) {
                    counts[role] = (counts[role] || 0) + 1;
                }
            });
            return counts;
        }, {});
    };

    // In the question card render section, update how questions are displayed
    return (
        <div className="container-fluid py-4">
            {/* Main Header */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Questions Dashboard</h3>
                <button 
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/teacher')}
                >
                    ← Back to Disciplines
                </button>
            </div>

            {/* Search and Filter Section */}
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">Search Questions</label>
                            <div className="input-group">
                                <span className="input-group-text">🔍</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by question ID..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Filter by Difficulty</label>
                            <select
                                className="form-select"
                                value={selectedDifficulty}
                                onChange={(e) => setSelectedDifficulty(e.target.value)}
                            >
                                <option value="all">All Difficulties</option>
                                {difficulties.map(difficulty => (
                                    <option key={difficulty} value={difficulty.toLowerCase()}>
                                        {difficulty}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Filter by Reviewer</label>
                            <select
                                className="form-select"
                                value={selectedReviewer}
                                onChange={(e) => setSelectedReviewer(e.target.value)}
                            >
                                <option value="all">All Questions</option>
                                <option value="teacher">Reviewed by Teacher</option>
                                <option value="designer">Reviewed by Designer</option>
                                <option value="copyright">Reviewed by Copyright</option>
                                <option value="admin">Reviewed by Admin</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            {/* Status Tabs */}
            <div className="card mb-4">
                <div className="card-body">
                    <ul className="nav nav-tabs">
                        {[
                            { id: 'all', label: 'All Questions', count: questionIds.length },
                            { id: 'approved', label: 'Approved', count: questionIds.filter(q => q.approved).length, color: 'success' },
                            { id: 'pending', label: 'In Progress', count: questionIds.filter(q => q.updated && !q.approved).length, color: 'warning' },
                            { id: 'not-started', label: 'Not Started', count: questionIds.filter(q => !q.updated && !q.approved).length, color: 'light text-dark border' }
                        ].map(tab => (
                            <li className="nav-item" key={tab.id}>
                                <button 
                                    className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    {tab.label}
                                    <span className={`badge bg-${tab.color || 'secondary'} ms-2`}>
                                        {tab.count}
                                    </span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Add Reviewer Stats */}
            <div className="card mb-4">
                <div className="card-body">
                    <h6>Review Statistics</h6>
                    <div className="d-flex gap-3">
                        {Object.entries(getReviewerCounts(questionIds)).map(([role, count]) => (
                            <div key={role} className="border rounded px-3 py-2">
                                <small className="text-muted">
                                    {role.charAt(0).toUpperCase() + role.slice(1)} Reviews
                                </small>
                                <h5 className="mb-0">{count}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Simplified Questions List */}
            <div className="card">
                <div className="card-body p-0">
                    <div className="questions-list">
                        {filterQuestions(questionIds).map(question => (
                            <QuestionCard 
                                key={question.id}
                                question={question} 
                                onSelect={handleQuestionSelect}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Question Detail Modal */}
            {selectedQuestion && (
                <QuestionDetailView 
                    question={selectedQuestion}
                    onClose={() => setSelectedQuestion(null)}
                    onEdit={(question) => {
                        setSelectedQuestion(question);
                        setShowEditModal(true);
                    }}
                />
            )}

            {/* Edit Modal */}
            {showEditModal && selectedQuestion && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Question {selectedQuestion.id}</h5>
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    onClick={() => setSelectedQuestion(null)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="question-details">
                                    <div className="mb-3">
                                        <h4>Old Question:</h4>
                                        <div className="markdown-content">
                                            {selectedQuestion.old_question ? (
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                    {selectedQuestion.old_question}
                                                </ReactMarkdown>
                                            ) : (
                                                <p>Not edited yet</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4>Original Question:</h4>
                                        <div className="markdown-content">
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                {selectedQuestion.new_question}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4>Edit Question:</h4>
                                        <textarea 
                                            value={newQuestionText} 
                                            onChange={(e) => setNewQuestionText(e.target.value)} 
                                            className="form-control" 
                                            rows="5"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <h4>Difficulty Level:</h4>
                                        <input 
                                            type="text" 
                                            value={difficulty} 
                                            className="form-control"
                                            readOnly
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <h4>Feedback:</h4>
                                        <textarea 
                                            value={feedbackText} 
                                            onChange={(e) => setFeedbackText(e.target.value)} 
                                            className="form-control" 
                                            rows="3"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <h4>Status:</h4>
                                        <select 
                                            value={approvalStatus} 
                                            onChange={(e) => setApprovalStatus(e.target.value)} 
                                            className="form-select"
                                        >
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="disapproved">Disapproved</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    onClick={() => {
                                        setShowEditModal(false);
                                        // Find the question and set it as selected to show the detail view
                                        setSelectedQuestion(selectedQuestion);
                                        // Don't clear selectedQuestionId here
                                    }}
                                >
                                    Back
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-primary"
                                    onClick={handleEditQuestion}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Status Legend */}
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title">Status Legend</h6>
                    <div className="d-flex gap-4">
                        <div className="d-flex align-items-center gap-2">
                            <span className="badge rounded-pill bg-success">✓</span>
                            <span>Approved</span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <span className="badge rounded-pill bg-warning">✎</span>
                            <span>Edited</span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <span className="badge rounded-pill bg-light border">○</span>
                            <span>Not Started</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Update TeacherDashboard to only handle discipline selection
const TeacherDashboard = ({ handleLogout }) => {
    const navigate = useNavigate();
    const [primaryDisciplines, setPrimaryDisciplines] = useState([]);
    const [secondaryDisciplines, setSecondaryDisciplines] = useState([]);
    const [selectedPrimaryDiscipline, setSelectedPrimaryDiscipline] = useState('');
    const [selectedSecondaryDiscipline, setSelectedSecondaryDiscipline] = useState('');
    const [assignedCourses, setAssignedCourses] = useState([]);
    const [selectedAssignedCourse, setSelectedAssignedCourse] = useState('');
    const [selectedAssignedSubcourse, setSelectedAssignedSubcourse] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAssignedCourses = async () => {
            try {
                const username = localStorage.getItem('username');
                const response = await axios.get(`${HOST}:8000/questions/${username}`);
                const questions = response.data;
                
                // Extract unique courses and subcourses from all categories
                const courseMap = new Map();
                
                // Process all question categories
                Object.values(questions).forEach(category => {
                    category.forEach(q => {
                        if (!q.course) return;
                        
                        if (!courseMap.has(q.course)) {
                            courseMap.set(q.course, new Set());
                        }
                        if (q.subcourse) {
                            courseMap.get(q.course).add(q.subcourse);
                        }
                    });
                });
                
                // Convert to array structure
                const courses = Array.from(courseMap).map(([course, subcourses]) => ({
                    course,
                    subcourses: Array.from(subcourses)
                }));
                
                setAssignedCourses(courses);
            } catch (error) {
                console.error('Error fetching assigned courses:', error);
                setError('Error loading courses');
            }
        };
        
        fetchAssignedCourses();
    }, []);

    const fetchDisciplinesByCourse = async (course, subcourse) => {
        setLoading(true);
        setError(null);
        try {
            const params = new URLSearchParams();
            if (course) params.append('course', course);
            if (subcourse) params.append('subcourse', subcourse);
            
            const response = await axios.get(`${HOST}:8000/disciplines/course?${params}`);
            
            setPrimaryDisciplines(response.data.primary_disciplines || []);
            setSecondaryDisciplines(response.data.secondary_disciplines || []);
        } catch (error) {
            console.error('Error fetching disciplines:', error);
            setError('Error loading disciplines');
            setPrimaryDisciplines([]);
            setSecondaryDisciplines([]);
        } finally {
            setLoading(false);
        }
    };

    const handleCourseChange = (e) => {
        const course = e.target.value;
        setSelectedAssignedCourse(course);
        setSelectedAssignedSubcourse('');
        setSelectedPrimaryDiscipline('');
        setSelectedSecondaryDiscipline('');
        if (course) {
            fetchDisciplinesByCourse(course);
        } else {
            setPrimaryDisciplines([]);
            setSecondaryDisciplines([]);
        }
    };

    const handleSubcourseChange = (e) => {
        const subcourse = e.target.value;
        setSelectedAssignedSubcourse(subcourse);
        setSelectedPrimaryDiscipline('');
        setSelectedSecondaryDiscipline('');
        fetchDisciplinesByCourse(selectedAssignedCourse, subcourse);
    };

    const handleDisciplineSubmit = () => {
        // Remove the validation since empty selections are now valid
        localStorage.setItem('selectedPrimaryDiscipline', selectedPrimaryDiscipline);
        localStorage.setItem('selectedSecondaryDiscipline', selectedSecondaryDiscipline);
        navigate('/teacher/questions');
    };

    return (
        <div className="dashboard">
            <h2 className="mt-5">Welcome, {localStorage.getItem('username')}</h2>
            <button 
                className="btn btn-warning mt-3" 
                onClick={handleLogout}
            >
                Logout
            </button>

            <div className="discipline-filters mt-4">
                <div className="card p-4">
                    <h4>Select Course and Filters</h4>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Select Course</label>
                            <select 
                                className="form-select"
                                value={selectedAssignedCourse}
                                onChange={handleCourseChange}
                            >
                                <option value="">All Courses</option>
                                {assignedCourses.map(course => (
                                    <option key={course.course} value={course.course}>
                                        {course.course}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                        {selectedAssignedCourse && (
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select Subcourse</label>
                                <select 
                                    className="form-select"
                                    value={selectedAssignedSubcourse}
                                    onChange={handleSubcourseChange}
                                >
                                    <option value="">All Subcourses</option>
                                    {assignedCourses
                                        .find(c => c.course === selectedAssignedCourse)
                                        ?.subcourses.map(subcourse => (
                                            <option key={subcourse} value={subcourse}>
                                                {subcourse}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        )}
                        
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Primary Discipline</label>
                            <select 
                                className="form-select"
                                value={selectedPrimaryDiscipline}
                                onChange={(e) => setSelectedPrimaryDiscipline(e.target.value)}
                            >
                                <option value="">All Primary Disciplines</option>
                                {primaryDisciplines.map(discipline => (
                                    <option key={discipline} value={discipline}>
                                        {discipline}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Secondary Discipline</label>
                            <select 
                                className="form-select"
                                value={selectedSecondaryDiscipline}
                                onChange={(e) => setSelectedSecondaryDiscipline(e.target.value)}
                            >
                                <option value="">All Secondary Disciplines</option>
                                {secondaryDisciplines.map(discipline => (
                                    <option key={discipline} value={discipline}>
                                        {discipline}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex gap-2 mt-3">
                        <button 
                            className="btn btn-primary"
                            onClick={handleDisciplineSubmit}
                        >
                            Load Questions
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Add new AdminDashboard component that combines course management and question assignment
const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [subcourses, setSubcourses] = useState([]);
    const [selectedSubcourse, setSelectedSubcourse] = useState('');
    const [questionStart, setQuestionStart] = useState(1);
    const [questionEnd, setQuestionEnd] = useState(5);
    const [assignMessage, setAssignMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [questionRange, setQuestionRange] = useState({ min: 1, max: 5 });

    useEffect(() => {
        fetchUsers();
        fetchCourses();
    }, []);

    useEffect(() => {
        if (selectedCourse) {
            fetchSubcourses(selectedCourse);
        }
    }, [selectedCourse]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${HOST}:8000/users`);
            setUsers(response.data.users || []);
        } catch (error) {
            console.error('Error fetching users:', error);
            setAssignMessage('Error loading teachers');
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await axios.get(`${HOST}:8000/admin/courses`);
            // Get unique courses from the response data
            const uniqueCourses = [...new Set(response.data.map(item => item.course))];
            setCourses(uniqueCourses);

            // Store full course data including subcourses
            const courseData = response.data;
            console.log('Course data received:', courseData);

            // If a course is already selected, update its subcourses
            if (selectedCourse) {
                const selectedCourseData = courseData.find(c => c.course === selectedCourse);
                if (selectedCourseData) {
                    setSubcourses(selectedCourseData.subcourses);
                }
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
            setAssignMessage('Error loading courses');
        }
    };

    const fetchSubcourses = async (course) => {
        try {
            setLoading(true);
            console.log('Fetching subcourses for course:', course);
            const response = await axios.get(`${HOST}:8000/admin/subcourses/${encodeURIComponent(course)}`);
            console.log('Subcourses response:', response.data);
            
            if (Array.isArray(response.data)) {
                setSubcourses(response.data);
            } else {
                console.error('Unexpected response format:', response.data);
                setSubcourses([]);
            }
        } catch (error) {
            console.error('Error fetching subcourses:', error);
            setSubcourses([]);
            setAssignMessage('Error loading subcourses');
        } finally {
            setLoading(false);
        }
    };

    const handleCourseChange = async (e) => {
        const selected = e.target.value;
        setSelectedCourse(selected);
        setSelectedSubcourse(''); // Reset subcourse selection
        
        if (selected) {
            // Fetch subcourses
            fetchSubcourses(selected);
            
            // Fetch question range for selected course
            try {
                const response = await axios.get(`${HOST}:8000/admin/course-question-range`, {  // Updated endpoint
                    params: { course: selected }
                });
                setQuestionRange(response.data);
                setQuestionStart(response.data.min_id);
                setQuestionEnd(response.data.max_id);
            } catch (error) {
                console.error('Error fetching question range:', error);
            }
        } else {
            setSubcourses([]);
            setQuestionRange({ min: 1, max: 5 });
        }
    };

    const handleSubcourseChange = async (e) => {
        const selected = e.target.value;
        setSelectedSubcourse(selected);
        
        if (selected && selectedCourse) {
            try {
                const response = await axios.get(`${HOST}:8000/admin/question-range`, {
                    params: { 
                        course: selectedCourse,
                        subcourse: selected 
                    }
                });
                setQuestionRange(response.data);
                setQuestionStart(response.data.min_id);
                setQuestionEnd(response.data.max_id);
            } catch (error) {
                console.error('Error fetching question range:', error);
            }
        }
    };

    const handleAssignment = async () => {
        if (!selectedTeacher || !selectedCourse || !selectedSubcourse || !questionStart || !questionEnd) {
            setAssignMessage('Please fill in all fields');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${HOST}:8000/assign-questions`, {
                teacher_id: selectedTeacher,
                course: selectedCourse,
                subcourse: selectedSubcourse,
                question_start: questionStart,
                question_end: questionEnd
            });
            setAssignMessage('Questions assigned successfully!');
            // Clear selections after successful assignment
            setSelectedSubcourse('');
            setQuestionStart(1);
            setQuestionEnd(5);
        } catch (error) {
            setAssignMessage('Error assigning questions. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveAssignments = async () => {
        if (!selectedTeacher) {
            setAssignMessage('Please select a teacher first');
            return;
        }

        if (window.confirm('Are you sure you want to remove all assignments from this teacher?')) {
            try {
                setLoading(true);
                const response = await axios.post(`${HOST}:8000/admin/remove-assignments/${selectedTeacher}`);
                setAssignMessage(`Success! ${response.data.affected_rows} assignments were removed.`);
                fetchUsers(); // Refresh the user list to update assignment counts
            } catch (error) {
                console.error('Error removing assignments:', error);
                setAssignMessage('Error removing assignments. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="container py-4">
            <h3>Question Assignment Dashboard</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">Select Teacher</label>
                            <select 
                                className="form-select"
                                value={selectedTeacher}
                                onChange={(e) => setSelectedTeacher(e.target.value)}
                            >
                                <option value="">Choose a teacher...</option>
                                {users.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.username} ({user.assigned_questions} assigned, {user.completed_questions} completed)
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Select Course</label>
                            <select 
                                className="form-select"
                                value={selectedCourse}
                                onChange={handleCourseChange}
                            >
                                <option value="">Choose a course...</option>
                                {courses.map(course => (
                                    <option key={course} value={course}>
                                        {course}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Select Subcourse</label>
                            <select 
                                className="form-select"
                                value={selectedSubcourse}
                                onChange={handleSubcourseChange}
                                disabled={!selectedCourse || loading}
                            >
                                <option value="">Choose a subcourse...</option>
                                {subcourses.map(subcourse => (
                                    <option key={subcourse} value={subcourse}>
                                        {subcourse}
                                    </option>
                                ))}
                            </select>
                            {loading && <div className="form-text">Loading subcourses...</div>}
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">Question Start</label>
                            <input 
                                type="number" 
                                className="form-control"
                                value={questionStart}
                                onChange={(e) => setQuestionStart(parseInt(e.target.value))}
                                min={questionRange.min_id}
                                max={questionRange.max_id}
                            />
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">Question End</label>
                            <input 
                                type="number" 
                                className="form-control"
                                value={questionEnd}
                                onChange={(e) => setQuestionEnd(parseInt(e.target.value))}
                                min={questionStart}
                                max={questionRange.max_id}
                            />
                            <small className="form-text text-muted">
                                Available range: {questionRange.min_id} - {questionRange.max_id}
                            </small>
                        </div>

                        <div className="col-12 d-flex gap-2">
                            <button 
                                className="btn btn-primary"
                                onClick={handleAssignment}
                                disabled={loading}
                            >
                                {loading ? 'Assigning...' : 'Assign Questions'}
                            </button>
                            
                            <button 
                                className="btn btn-danger"
                                onClick={handleRemoveAssignments}
                                disabled={loading || !selectedTeacher}
                            >
                                {loading ? 'Removing...' : 'Remove All Assignments'}
                            </button>
                        </div>

                        {assignMessage && (
                            <div className="col-12">
                                <div className={`alert ${assignMessage.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
                                    {assignMessage}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [filter, setFilter] = useState('all'); // Add filter state

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${HOST}:8000/admin/users/pending`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setMessage('Error loading users');
        }
    };

    const handleApprove = async (userId) => {
        try {
            await axios.post(`${HOST}:8000/admin/users/approve/${userId}`);
            setMessage('User approved successfully');
            fetchUsers();
        } catch (error) {
            setMessage('Error approving user');
        }
    };

    const handleReject = async (userId) => {
        if (window.confirm('Are you sure you want to reject this user?')) {
            try {
                await axios.post(`${HOST}:8000/admin/users/reject/${userId}`);
                setMessage('User rejected successfully');
                fetchUsers();
            } catch (error) {
                setMessage('Error rejecting user');
            }
        }
    };

    const handleDelete = async (userId) => {
        if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
            try {
                await axios.delete(`${HOST}:8000/admin/users/delete/${userId}`);
                setMessage('User deleted successfully');
                fetchUsers();
            } catch (error) {
                setMessage('Error deleting user');
                console.error('Error:', error);
            }
        }
    };

    const filteredUsers = () => {
        switch(filter) {
            case 'pending':
                return users.filter(user => user.pending);
            case 'approved':
                return users.filter(user => user.approved);
            default:
                return users;
        }
    };

    const getUserStatus = (user) => {
        if (user.pending) return { label: 'Pending', color: 'warning' };
        if (user.approved) return { label: 'Approved', color: 'success' };
        return { label: 'Not Approved', color: 'danger' };
    };

    return (
        <div className="container py-4">
            <h3>User Management</h3>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4>User Management</h4>
                        <div className="btn-group">
                            <button 
                                className={`btn btn${filter === 'all' ? '' : '-outline'}-primary`}
                                onClick={() => setFilter('all')}
                            >
                                All Users
                            </button>
                            <button 
                                className={`btn btn${filter === 'pending' ? '' : '-outline'}-warning`}
                                onClick={() => setFilter('pending')}
                            >
                                Pending
                            </button>
                            <button 
                                className={`btn btn${filter === 'approved' ? '' : '-outline'}-success`}
                                onClick={() => setFilter('approved')}
                            >
                                Approved
                            </button>
                        </div>
                    </div>

                    {message && (
                        <div className="alert alert-info mb-4">{message}</div>
                    )}

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers().map(user => {
                                    const status = getUserStatus(user);
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.username}</td>
                                            <td>{user.role}</td>
                                            <td>
                                                <span className={`badge bg-${status.color}`}>
                                                    {status.label}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    {user.pending && (
                                                        <>
                                                            <button 
                                                                className="btn btn-success btn-sm"
                                                                onClick={() => handleApprove(user.id)}
                                                            >
                                                                Approve
                                                            </button>
                                                            <button 
                                                                className="btn btn-warning btn-sm"
                                                                onClick={() => handleReject(user.id)}
                                                            >
                                                                Reject
                                                            </button>
                                                        </>
                                                    )}
                                                    <button 
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDelete(user.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FullscreenImageModal = ({ image, onClose }) => {
    if (!image) return null;

    return (
        <div 
            className="modal show d-block" 
            style={{
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: 1050,
                overflow: 'hidden'
            }}
            onClick={onClose}
        >
            <div 
                className="modal-dialog modal-dialog-centered m-0"
                style={{ 
                    maxWidth: '100vw', 
                    height: '100vh',
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div 
                    className="d-flex justify-content-center align-items-center h-100 w-100"
                    onClick={e => e.stopPropagation()}
                    style={{ padding: '20px' }}
                >
                    <img
                        src={image.replace("'","").replace("'","").replace("[","").replace("]","")}
                        alt="Fullscreen view"
                        style={{
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            width: 'auto',
                            height: 'auto',
                            objectFit: 'contain',
                            margin: 'auto'
                        }}
                    />
                </div>
            </div>
            <button 
                className="btn-close btn-close-white position-absolute top-0 end-0 m-4"
                onClick={onClose}
                style={{ zIndex: 1051 }}
            ></button>
        </div>
    );
};

const ImageGallery = ({ questionId, onImageDeleted }) => {
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchImages();
    }, [questionId]);

    const fetchImages = async () => {
        try {
            const response = await axios.get(`${HOST}:8000/questions/${questionId}/images`);
            setImages(response.data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        setUploading(true);
        try {
            const response = await axios.post(
                `${HOST}:8000/questions/${questionId}/images`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );
            if (response.data.path) {
                setImages([...images, response.data.path]);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleDeleteImage = async (imagePath) => {
        if (window.confirm('Are you sure you want to delete this image?')) {
            try {
                await axios.delete(`${HOST}:8000/questions/${questionId}/images/${imagePath}`);
                setImages(images.filter(img => img !== imagePath));
            } catch (error) {
                console.error('Error deleting image:', error);
            }
        }
    };

    return (
        <div className="image-gallery">
            <div className="mb-3">
                <h6 className="border-bottom pb-2 mb-3">Image Gallery</h6>
                <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileSelect}
                    className="form-control mb-2"
                    disabled={uploading}
                />
            </div>
            
            <div className="row g-3">
                {images.map((image, index) => (
                    <div key={index} className="col-md-4">
                        <div className="card h-100">
                            <div className="image-container" style={{ 
                                height: '200px', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                backgroundColor: '#f8f9fa'
                            }}>
                                <img
                                    src={`${image.replace("'","").replace("'","").replace("[","").replace("]","")}`}
                                    alt={`Image ${index + 1}`}
                                    style={{ 
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                        objectFit: 'contain',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSelectedImage(image)}
                                />
                            </div>
                            <div className="card-body d-flex justify-content-center">
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDeleteImage(image)}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            
            {uploading && (
                <div className="text-center mt-3">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Uploading...</span>
                    </div>
                </div>
            )}

            <FullscreenImageModal 
                image={selectedImage}
                onClose={() => setSelectedImage(null)}
            />
        </div>
    );
};

export default function App() {
        // Add new state variables for roles at the top with other state declarations
        const [role, setRole] = useState('teacher');
    

    // Add new state variables for disciplines
    const [primaryDisciplines, setPrimaryDisciplines] = useState([]);
    const [secondaryDisciplines, setSecondaryDisciplines] = useState([]);
    const [selectedPrimaryDiscipline, setSelectedPrimaryDiscipline] = useState('');
    const [selectedSecondaryDiscipline, setSelectedSecondaryDiscipline] = useState('');
    
    const [currentUsername, setCurrentUsername] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [userRole, setUserRole] = useState(null);  // User role to determine if admin or regular user
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [newQuestionText, setNewQuestionText] = useState("");
    const [feedbackText, setFeedbackText] = useState("");
    const [difficulty, setDifficulty] = useState("Easy");
    const [approvalStatus, setApprovalStatus] = useState("pending");
    const [loginError, setLoginError] = useState("");
    const [registerMessage, setRegisterMessage] = useState("");
    const [users, setUsers] = useState([]);  // List of users for admin to assign questions
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [assignMessage, setAssignMessage] = useState("");  // Message to display after assigning questions
    const [questionStart, setQuestionStart] = useState(1);
    const [questionEnd, setQuestionEnd] = useState(5);
    const [selectedImage, setSelectedImage] = useState(null); // Add new state for image upload
    const [imagePreview, setImagePreview] = useState(null);
    const [showGallery, setShowGallery] = useState(false);
    const [selectedImageInGallery, setSelectedImageInGallery] = useState(null);
    const navigate = useNavigate();

    // Add useEffect to check localStorage on app load
    useEffect(() => {
        const savedUsername = localStorage.getItem('username');
        const savedRole = localStorage.getItem('userRole');
        
        if (savedUsername && savedRole) {
            setCurrentUsername(savedUsername);
            setUserRole(savedRole);
            if (savedRole === 'admin') {
                navigate('/admin');
            } else {
                navigate('/teacher');
            }
        }
    }, []);

    useEffect(() => {
        if (currentUsername) {
            console.log('Fetching questions for:', currentUsername);
            axios.get(`${HOST}:8000/admin/${currentUsername}`, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    console.log('Questions fetched successfully:', response.data);
                    setQuestions(response.data);
                })
                .catch(error => {
                    console.error('Error fetching questions:', error);
                });
        }
    }, [currentUsername, userRole]);

    useEffect(() => {
        if (userRole === 'admin') {
            console.log('Fetching users for admin');
            axios.get(`${HOST}:8000/users`, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    console.log('Users fetched successfully:', response.data);
                    setUsers(response.data);
                })
                .catch(error => {
                    console.error('Error fetching users:', error);
                });
        }
    }, [userRole]);

    const fetchUserId = (username) => {
        console.log('Fetching user ID for username:', username);
        axios.get(`${HOST}:8000/user-id/${username}`, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log('User ID fetched successfully for username:', username, 'User ID:', response.data.user_id);
                setSelectedUserId(response.data.user_id);
            })
            .catch(error => {
                console.error('Error fetching user ID for username:', username, error);
            });
    };

    // Update handleLogin to save to localStorage
    const handleLogin = async (username, password) => {
        try {
            const response = await axios.post(`${HOST}:8000/login`, { 
                username, 
                password 
            });
            if (response.data.message === 'Login successful') {
                localStorage.setItem('username', username);
                localStorage.setItem('userRole', response.data.role);
                
                setCurrentUsername(username);
                setUserRole(response.data.role);
                setLoginError("");
                
                // Redirect based on role
                switch(response.data.role) {
                    case 'designer':
                        navigate('/designer-dashboard');
                        break;
                    case 'teacher':
                        navigate('/teacher');
                        break;
                    case 'admin':
                        navigate('/teacher');
                        break;
                    default:
                        navigate('/');
                }
            } else {
                setLoginError('Invalid username or password');
            }
        } catch (error) {
            if (error.response?.status === 403) {
                setLoginError(error.response.data.message);
            } else {
                setLoginError('Invalid username or password');
            }
        }
    };


    const handleAdminLogin = async (username, password) => {
        try {
            const response = await axios.post(`${HOST}:8000/login`, { 
                username, 
                password 
            });
            if (response.data.message === 'Login successful') {
                console.log('Login successful for:', username);
                // Save to localStorage
                localStorage.setItem('username', username);
                localStorage.setItem('userRole', response.data.role);
                
                setCurrentUsername(username);
                setUserRole(response.data.role);
                setLoginError("");
                
                if (response.data.role === 'admin') {
                    navigate('/admin');
                } else {
                    navigate('/teacher');
                }
            } else {
                setLoginError('Invalid username or password');
            }
        } catch (error) {
            if (error.response?.status === 403) {
                setLoginError(error.response.data.message);
            } else {
                setLoginError('Invalid username or password');
            }
        }
    };


    const handleRegister = (username, password, role) => {
        if (username && password) {
            console.log('Attempting registration for:', username);
            axios.post(`${HOST}:8000/register`, { 
                username, 
                password, 
                role  // Pass the role from state
            }, { 
                headers: { 'Content-Type': 'application/json' } 
            })            .then(response => {
                console.log('Registration successful for:', username);
                setRegisterMessage('User registered successfully. You can now log in.');
                setLoginError('');
            })


                .catch(error => {
                    setRegisterMessage('Registration failed: Username may already exist');
                    setLoginError('');
                    console.error('Registration error:', error);
                });
        } else {
            setRegisterMessage('Please fill in all fields');
            setLoginError('');
        }
    };

    const handleAssignQuestions = () => {
        if (selectedUserId && questionStart > 0 && questionEnd >= questionStart) {
            console.log('Assigning questions:', { selectedUserId, questionStart, questionEnd });
            try {
                axios.post(`${HOST}:8000/assign-questions`, {
                    user_id: selectedUserId,
                    question_start: questionStart,
                    question_end: questionEnd
                }, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        console.log('Questions assigned successfully:', response.data);
                        setAssignMessage('Questions assigned successfully.');
                    })
                    .catch(error => {
                        setAssignMessage('Error assigning questions. Please try again.');
                        console.error('Error assigning questions:', error);
                    });
            } catch (error) {
                console.error('Unexpected error while assigning questions:', error);
            }
        } else {
            console.warn('Invalid input for assigning questions:', { selectedUserId, questionStart, questionEnd });
            setAssignMessage('Please select a user and provide a valid range of questions (start and end must be greater than 0, and start must be less than or equal to end).');
        }
    };

    // Update the handleLogout function
    const handleLogout = () => {
        console.log('User logged out:', currentUsername);
        // Clear localStorage
        localStorage.removeItem('username');
        localStorage.removeItem('userRole');
        
        // Clear all states
        setCurrentUsername(null);
        setLoginError('');
        setRegisterMessage('');
        setUserRole(null);
        setAssignMessage('');
        setQuestions([]);
        setSelectedQuestionId(null);
        setImagePreview(null);
        setSelectedImage(null);
        setShowGallery(false);
        setSelectedImageInGallery(null);
        
        // Navigate to home page
        navigate('/', { replace: true });
    };

    return (
        <div className="container">
            <Routes>
                <Route path="/" element={
                    !currentUsername ? (
                        <div className="row">
                            <div className="col-md-4 offset-md-4 mt-5">
                                <div className="card p-4">
                                    <h2 className="text-center">Teacher Login</h2>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="Username" id="username" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="password" />
                                    </div>
                                    <button className="btn btn-primary w-100" onClick={() => handleLogin(document.getElementById('username').value, document.getElementById('password').value)}>Login as Teacher</button>
                                    {loginError && <p className="text-danger mt-2 text-center">{loginError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 offset-md-4 mt-3">
                                <div className="card p-4">
                                    <h2 className="text-center">Admin Login</h2>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="Admin Username" id="admin-username" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="admin-password" />
                                    </div>
                                    <button className="btn btn-danger w-100" onClick={() => handleAdminLogin(document.getElementById('admin-username').value, document.getElementById('admin-password').value)}>Login as Admin</button>
                                    {loginError && <p className="text-danger mt-2 text-center">{loginError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 offset-md-4 mt-3">
                                <div className="card p-4">
                                <h2 className="text-center">Register</h2>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="Username" id="register-username" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="register-password" />
                                    </div>
                                    <select 
                                        className="form-select mb-3" 
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        <option value="teacher">Teacher</option>
                                        <option value="designer">Designer</option>
                                        <option value="copyright">Copyright</option>
                                    </select>
                                    <button 
                                        className="btn btn-success w-100" 
                                        onClick={() => handleRegister(
                                            document.getElementById('register-username').value,
                                            document.getElementById('register-password').value,
                                            role

                                        )}
                                    >
                                        Register
                                    </button>

                             
                                    {registerMessage && <p className="text-success mt-2 text-center">{registerMessage}</p>}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Navigate to={userRole === 'admin' ? '/admin' : '/teacher'} replace />
                    )
                } />

                <Route 
                    path="/admin/*" 
                    element={
                        userRole === 'admin' ? (
                            <AdminLayout username={currentUsername} onLogout={handleLogout} />
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                >
                    <Route index element={<AdminDashboard />} />
                    <Route path="database" element={<DatabaseManagement />} />
                    <Route path="users" element={<UserManagement />} />
                </Route>

                <Route path="/teacher" element={
                    currentUsername ? (
                        <TeacherDashboard 
                            handleLogout={handleLogout}  // Pass handleLogout as prop
                        />
                    ) : (
                        <Navigate to="/" replace />
                    )
                } />
                <Route path="/teacher/questions" element={
                currentUsername ? (
                    <ViewQuestions />
                ) : (
                    <Navigate to="/" replace />
                )                } />                
                <Route path="*" element={<Navigate to="/" replace />} />            

                <Route path="/designer-dashboard" element={
                    currentUsername ? (
                        <DesignerDashboard />
                    ) : (
                        <Navigate to="/" replace />
                    )
                } />

                <Route path="/question/:id" element={
                    currentUsername ? (
                        <QuestionDetail />
                    ) : (
                        <Navigate to="/" replace />
                    )
                } />
            </Routes>        
        </div>    
    );
}

function DesignerDashboard() {
    console.log("DesignerDashboard component rendered"); // Add this line
    const [questions, setQuestions] = useState({
        from_teacher: [],
        from_copyright: [],
        from_admin: []
    });
    const [statusFilter, setStatusFilter] = useState('all');
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const username = localStorage.getItem('username');
                const response = await axios.get(`${HOST}:8000/questions/${username}`);
                setQuestions(response.data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions();
    }, []);

    // Filter questions based on status
    const filterQuestionsByStatus = (questionList) => {
        switch(statusFilter) {
            case 'not_started':
                return questionList.filter(q => !q.updated && !q.approved);
            case 'in_progress':
                return questionList.filter(q => q.updated && !q.approved);
            case 'completed':
                return questionList.filter(q => q.approved);
            default:
                return questionList;
        }
    };

    // Get counts for status badges
    const getStatusCounts = (questionList) => ({
        all: questionList.length,
        not_started: questionList.filter(q => !q.updated && !q.approved).length,
        in_progress: questionList.filter(q => q.updated && !q.approved).length,
        completed: questionList.filter(q => q.approved).length
    });

    const handleQuestionClick = async (question) => {
        try {
            setIsLoading(true);
            setError(null);
            
            // Fetch full question details if needed
            const response = await axios.get(`${HOST}:8000/questions/detail/${question.id}`);
            const detailedQuestion = response.data;
            
            // Update selected question in state
            setSelectedQuestion(detailedQuestion);
            
            // Navigate to question detail view
            navigate(`/question/${question.id}`, { 
                state: { 
                    question: detailedQuestion,
                    returnPath: '/designer-dashboard'  // For back navigation
                }
            });
        } catch (error) {
            console.error('Error loading question details:', error);
            setError('Failed to load question details');
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <div className="alert alert-danger m-3">
                {error}
                <button 
                    className="btn btn-outline-danger ms-3"
                    onClick={() => setError(null)}
                >
                    Dismiss
                </button>
            </div>
        );
    }

    return (
        <div className="designer-dashboard p-4">
            {isLoading && (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" 
                     style={{ backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 1050 }}>
                    <div className="card p-3">
                        <div className="d-flex align-items-center">
                            <div className="spinner-border text-primary me-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <span>Loading question details...</span>
                        </div>
                    </div>
                </div>
            )}

            {/* Status Filter Tabs */}
            <div className="card mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">Design Review Dashboard</h4>
                        <div className="btn-group">
                            {[
                                { id: 'all', label: 'All Questions' },
                                { id: 'not_started', label: 'Not Started', variant: 'secondary' },
                                { id: 'in_progress', label: 'In Progress', variant: 'warning' },
                                { id: 'completed', label: 'Completed', variant: 'success' }
                            ].map(filter => {
                                const counts = getStatusCounts([
                                    ...questions.from_teacher,
                                    ...questions.from_copyright,
                                    ...questions.from_admin
                                ]);
                                return (
                                    <button
                                        key={filter.id}
                                        className={`btn btn${statusFilter === filter.id ? '' : '-outline'}-${filter.variant || 'primary'}`}
                                        onClick={() => setStatusFilter(filter.id)}
                                    >
                                        {filter.label}
                                        <span className="badge bg-white text-dark ms-2">
                                            {counts[filter.id]}
                                        </span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* Question Grid */}
            <div className="row g-4">
                {[
                    {
                        title: 'New Design Reviews',
                        subtitle: 'From Teachers',
                        questions: questions.from_teacher,
                        color: 'primary',
                        icon: ''
                    },
                    {
                        title: 'Design Changes Needed',
                        subtitle: 'From Copyright Team',
                        questions: questions.from_copyright,
                        color: 'warning',
                        icon: '🔄'
                    },
                    {
                        title: 'Priority Reviews',
                        subtitle: 'From Admin',
                        questions: questions.from_admin,
                        color: 'danger',
                        icon: '⚡'
                    }
                ].map((section, index) => (
                    <div key={index} className="col-md-4">
                        <div className="card h-100 shadow-sm">
                            <div className={`card-header bg-${section.color} text-white d-flex justify-content-between align-items-center`}>
                                <div>
                                    <h5 className="mb-0">{section.icon} {section.title}</h5>
                                    <small>{section.subtitle}</small>
                                </div>
                                <span className="badge bg-white text-dark">
                                    {filterQuestionsByStatus(section.questions).length}
                                </span>
                            </div>
                            <div className="card-body p-0">
                                <QuestionList 
                                    questions={filterQuestionsByStatus(section.questions)}
                                    emptyMessage={`No ${section.title.toLowerCase()}`}
                                    onQuestionClick={handleQuestionClick}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

// Updated QuestionList component
function QuestionList({ questions, emptyMessage, onQuestionClick, isLoading }) {
    return questions.length > 0 ? (
        <div className="question-list">
            {questions.map(question => (
                <div 
                    key={question.id} 
                    className={`question-item px-3 py-2 border-bottom hover-bg ${isLoading ? 'disabled' : ''}`}
                    onClick={() => !isLoading && onQuestionClick(question)}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                            <span className="badge bg-secondary">#{question.id}</span>
                            {question.difficulty && (
                                <span className={`badge ${getDifficultyColor(question.difficulty)}`}>
                                    {question.difficulty}
                                </span>
                            )}
                            {question.updated && !question.approved && (
                                <span className="badge bg-warning">In Progress</span>
                            )}
                            {question.approved && (
                                <span className="badge bg-success">Completed</span>
                            )}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            {question.editor && (
                                <small className="text-muted">
                                    Edited by: {question.editor}
                                </small>
                            )}
                            <button 
                                className="btn btn-sm btn-outline-primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!isLoading) onQuestionClick(question);
                                }}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Review'}
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div className="text-center py-4">
            <p className="text-muted mb-0">{emptyMessage}</p>
        </div>
    );
}

// Updated CSS
const styles = `
.question-item {
    transition: all 0.2s;
    cursor: pointer;
}

.question-item:hover {
    background-color: var(--bs-gray-100);
}

.question-item.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.question-list {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.question-item .btn {
    opacity: 0.7;
}

.question-item:hover .btn {
    opacity: 1;
}
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

// Add QuestionDetail component
function QuestionDetail() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const question = state?.question;
    const returnPath = state?.returnPath || '/designer-dashboard';

    if (!question) {
        return <Navigate to={returnPath} replace />;
    }

    return (
        <div className="container py-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Question #{question.id}</h3>
                <button 
                    className="btn btn-outline-primary"
                    onClick={() => navigate(returnPath)}
                >
                    Back to Dashboard
                </button>
            </div>
            
            {/* Question detail view content */}
            <div className="card">
                <div className="card-body">
                    <h5>Question Details</h5>
                    {/* Add your question editing/review interface here */}
                </div>
            </div>
        </div>
    );
}